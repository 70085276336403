<template>
  <screen_width_cont>
    <div class="recoverPassCont cont_300_basico ancho_acotado">
      <div class="recoverPassFinalizar">
        <div class="recoverPassFinalizarCard">
          <div class="recoverPassFinalizarCheck">
            <img :src="iconCotizadorFinalizarCheckAzul" alt="" srcset="" />
          </div>

          <div class="recoverPassFinalizarTitle title">
            {{ t("app.recoverPassFinalizar.tit") }}
          </div>

          <div class="recoverPassFinalizarText1 text_basico f_w_400">
            {{ t("app.recoverPassFinalizar.text") }}
          </div>

          <div class="recoverPassFinalizarBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.finalizar')"
              @action="finalizar"
              colorBack="azul"
              colorText="blco"
              estadoBtn="active"
            />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
// import { IonContent, IonPage } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import { mapState } from "vuex";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "recoverPassFinalizar",
  components: {
    btnAction,
    screen_width_cont,
  },
  data() {
    return {
      iconCotizadorFinalizarCheckAzul: require("../assets/imgs/iconVerificaCuponCheckAzul.svg"),
    };
  },
  computed: {
    ...mapState(["widthScreen"]),
  },
  methods: {
    finalizar() {
      this.$router.push({ name: "logIn", replace: true });
    },
  },
};
</script>

<style>
.recoverPassCont {
  width: 100%;
  padding: 0 15% 0 15%;
  position: relative;
}
.recoverPassFinalizar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.recoverPassFinalizarCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 3.5625rem 8% 3.125rem 8%;
}
.recoverPassFinalizarCheck {
  text-align: center;
}
.recoverPassFinalizarTitle {
  margin-top: 1.5625rem;

  text-align: center;
  color: #614ad3;
}
.recoverPassFinalizarText1 {
  margin-top: 1.5625rem;
  text-align: center;
  color: #787878;
}
.recoverPassFinalizarText1 span {
  font-weight: 700;
}
.recoverPassFinalizarBtn {
  margin-top: 3.4375rem;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .recoverPassCont {
  width: 100%;
  padding: 0 ;
  position: relative;
}
}
</style>